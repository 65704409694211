import './App.css';
import { Container, Button, GridList, GridListTile, GridListTileBar, IconButton, TextField } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { Image, Warning, Check, Help } from '@material-ui/icons';
import { green, grey } from '@material-ui/core/colors';
import Loader from "react-loader-spinner";
import React from 'react';
import axios from 'axios';


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    var meta
    var str
    [meta, str] = reader.result.split(',')
    resolve({meta, str})
  }
  reader.onerror = error => reject(error)
})

class AppContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      showingResults: false,
      errorMsg: null,
      files: [],
      images: [],
      apiKey: null
    };
  }  

  async processFiles() {
    let files = this.state.files.map(file => toBase64(file))
    let result = await Promise.all(files)
    let requests = result.map(item => {
      return axios.post(
        // TODO: MAKE THIS A VARIABLE
        'https://embar.link/eaapi/prediction-request', 
        {image: item.str}, 
        {'headers': {
          'x-api-key': this.state.apiKey
        }}
      ).catch(err => {
        return Promise.resolve({ data: {
          error: true,
          statusCode: err.response.status,
          errorMsg: err.response.statusText
        }})
      })
    })

    let responses = await Promise.all(requests)
    // associate response with image
    let merged = result.map((img, index) => {
      let prediction = responses[index]
      return {...prediction.data, ...img}
    })
    this.setState({images: merged})
    this.setState({processing: false, showingResults: true})
  }

  reset() {
    // re initialize everything, except keep the api key
    this.setState({
      processing: false,
      showingResults: false,
      errorMsg: null,
      files: [],
      images: [],
    })
  }
  
  render() {
    // SELECT IMAGES & SET KEY
    if (! (this.state.showingResults || this.state.processing) ) {
      return(
        <Container>
          <DropzoneArea Icon={Image} acceptedFiles={['image/*']} dropzoneText={"Add 9 images to test"} 
            onChange={(files) => this.setState({files})} filesLimit={9} showAlerts={false} cellHeight={320} />
          
          <br/>
          <form noValidate autoComplete="off">
            <TextField id="api-input" label="Your API Key" variant="outlined" type="password" 
              onChange={(event) => this.setState({apiKey: event.target.value})}
              defaultValue={this.state.apiKey}
            />
          </form>
          <br/>
          <Button variant="contained" color="primary" 
            disabled = {this.state.files.length !== 9 || this.state.apiKey == null}
            onClick={() => {
              this.setState({processing: true})
              this.processFiles()
            }} > 
            Check for Embers
          </Button>
        </Container>
      )
    // PROCESSING IMAGES
    } else if (this.state.processing) {
      return (
        // TODO show progress bar and please wait
        <Container justify="center">
          <Loader type="Grid" />
          <h3>Please wait while your images are analyzed</h3>
        </Container>
      )
  // SHOWING RESULTS
  } else if (this.state.showingResults) {
      return (
        // show images grid with results
        <Container>
        <GridList cellHeight={320} cols={3}>
          {this.state.images.map((tile, index) =>
            <GridListTile key={index}>
              <img src={tile.meta + "," + tile.str} alt="" />
              <GridListTileBar 
                title={tile.error ? 'Error: ' + tile.statusCode : (tile.emberAttack ? < Warning color="secondary" /> : <Check style={{ color: green[500]}} /> )} 
                subtitle={tile.error? tile.errorMsg: (tile.emberAttack ? "Activate sprinklers and notify property manager": "No Ember Attack detected")}
                // actionIcon={ <IconButton>< Help style={{ color: grey[500] }} /></IconButton> }
              />
            </GridListTile>
          )}
        </GridList>
        <br/>
        <Button variant="contained"
          onClick={ this.reset.bind(this) } > 
          Reset
        </Button>
        </Container>
      )
    }
  }
}

function App() {
  return (
    <div className="App">
      <Container> <h1>Ember Detection Web Service</h1> </Container>
      <Container> <h2>Interactive Demo</h2> </Container>
      <AppContent />
    </div>
  );
}

export default App;
